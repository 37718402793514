import "./ModelSelector.scss";
import { useEffect, useRef, useState } from "react";
import { useConfig } from "../../contexts/useConfig";
import { useViewerMode } from "../../contexts/useViewerMode";
import { useCurrentVariant } from "../../contexts/useCurrentVariant";
import { motion } from "framer-motion"


import ModelButton from "../Buttons/Model/ModelButton";


function setupDragScroll() {
   const scrollView = document.querySelector(".grab-scroll");
   scrollView.scrollTop = 0;
   scrollView.scrollLeft = 0;
   let pos = { top: 0, left: 0, x: 0, y: 0 };
   let dx = 0;
   let dy = 0;

   const mouseDownHandler = function (e) {
      scrollView.style.userSelect = "none";

      pos = {
         // The current scroll
         left: scrollView.scrollLeft, top: scrollView.scrollTop,
         // Get the current mouse position
         x: e.clientX || e.touches[0].pageX, 
         y: e.clientY || e.touches[0].pageY
      };

      dx = 0;
      dy = 0;

      scrollView.addEventListener("mousemove", mouseMoveHandler);
      scrollView.addEventListener("mouseup", mouseUpHandler);
      scrollView.addEventListener("touchmove", mouseMoveHandler);
      scrollView.addEventListener("touchend", mouseUpHandler);


   };

   const mouseMoveHandler = function (e) {
      
      // How far the mouse has been moved
      dx = (e.clientX || e.touches[0].pageX) - pos.x;
      scrollView.scrollLeft = pos.left - dx;
      dy = (e.clientY || e.touches[0].pageY) - pos.y;
      scrollView.scrollTop = pos.top - dy;
   };

   const mouseUpHandler = function () {
      function preventClickOnDrag(event) {
         if (Math.abs(dx) > 5) {
            event.preventDefault();
            event.stopImmediatePropagation();
         }
         if (Math.abs(dy) > 5) {
            event.preventDefault();
            event.stopImmediatePropagation();
         }
      }

      scrollView.addEventListener("click", preventClickOnDrag, true);

      scrollView.removeEventListener("mousemove", mouseMoveHandler);
      scrollView.removeEventListener("mouseup", mouseUpHandler);
      scrollView.removeEventListener("touchmove", mouseMoveHandler);
      scrollView.removeEventListener("touchend", mouseUpHandler);

      scrollView.style.cursor = "grab";
      scrollView.style.removeProperty("user-select");
   };

   scrollView.addEventListener("mousedown", mouseDownHandler);
   scrollView.addEventListener("touchstart", mouseDownHandler);
}

let currentModel = null;


export function getCurrentModel (){
   return currentModel;
}

export default function ModelSelector(props) {
   const [config] = useConfig();
   const [viewerMode, setViewerMode] = useViewerMode();
   const [currentVariant] = useCurrentVariant();
   const [centerVertically, setCenterVertically] = useState(false)
   const productSelectorListRef = useRef();


   const calculateVerticalPosition = () =>{
      if(productSelectorListRef.current) {
         const thumbSize = getComputedStyle(productSelectorListRef.current).getPropertyValue("--thumb-size");
         const paddingVertical = getComputedStyle(productSelectorListRef.current).getPropertyValue("--padding-vertical");
         const marginBottom = getComputedStyle(productSelectorListRef.current).getPropertyValue("--margin-bottom");
         const windowHeight = window.innerHeight;
   
   
   
         let variantCount = 0;
         for(let product of config.products) {
            console.log(product)
            variantCount += product.variants.length;
         }

         //css size variable set in rem, gap is 1rem for now
         const thumbSizeRem = parseFloat(thumbSize.replace("rem", ""));
         const paddingVerticalRem = parseFloat(paddingVertical.replace("rem", ""));
         const marginBottomRem = parseFloat(marginBottom.replace("rem", ""));

         console.log("vc",variantCount)
         console.log(thumbSize,paddingVertical, marginBottom)
         console.log(thumbSizeRem,paddingVerticalRem, marginBottomRem)
         const gapSizeRem = 1;
         const approxListHeight = (variantCount * (thumbSizeRem + gapSizeRem) - gapSizeRem) + paddingVerticalRem + marginBottomRem  + 2;
         const windowHeightRem = windowHeight/16;

         console.log(approxListHeight)
         
         if(approxListHeight < windowHeightRem){
            setCenterVertically(true);
         } else {
            setCenterVertically(false)
         }
      }
   }
   window.addEventListener("resize", calculateVerticalPosition)

   
   currentModel = currentVariant;

   function closeOnEscapeKey(event) {
      const productSelector = document.querySelector(".product-selector");
      const productSelectorVisible = !productSelector.classList.contains("product-selector-hidden");
      productSelectorVisible && event.key === "Escape" && setViewerMode("view");
   }

   const handleMouseClickedBackground = () => {
      setViewerMode("view")
   }

   const onModelSelected = (model) => {
      currentModel = model;
      setViewerMode("view");
   } 

   useEffect(() => {
      setupDragScroll();
      calculateVerticalPosition();
      window.addEventListener("keyup", closeOnEscapeKey);
      return function cleanup() {
         window.removeEventListener("keyup", closeOnEscapeKey);
      };
      // eslint-disable-next-line
   }, []);

   const variants = {
      open: {scale: 1, opacity: 1, "pointer-events": "initial", display: "flex"},
      closed: {scale:0, opacity: 0, "pointer-events": "none", display: "none"}
   }

   return (
      <motion.div
      variants={variants}
      animate={viewerMode === "select" ? "open" : "closed"}
      transition={{duration: 0.5}}
         className={viewerMode === "select" ? "product-selector" : "product-selector product-selector-hidden"}
         onClick={handleMouseClickedBackground}
      >
         <ul className={`product-selector-list grab-scroll ${centerVertically ? "center-vertically" : ""}`} ref={productSelectorListRef}>
            {config.products.map((product) =>
               // Products loop
               product.variants.map((variant, index) => {
                  // Variants loop
                  return (
                     <ModelButton
                        className={variant.uuid === currentVariant.uuid && "active"}
                        product={product}
                        model={variant}
                        key={variant.uuid}
                        onModelSelected={onModelSelected}
                     ></ModelButton>
                  );
               })
            )}
         </ul>
      </motion.div>
   );
}
