import "./ContentIcon.css";

import FileIconWhite from "../../assets/icons/hotspots/white/file-text.svg";
import GalleryIconWhite from "../../assets/icons/hotspots/white/gallery.svg";
import ImageIconWhite from "../../assets/icons/hotspots/white/image.svg";
import ListIconWhite from "../../assets/icons/hotspots/white/list.svg";
import LinkIconWhite from "../../assets/icons/hotspots/white/link.svg";
import PlayIconWhite from "../../assets/icons/hotspots/white/play.svg";
import PlusIconWhite from "../../assets/icons/hotspots/white/plus.svg";
import YoutubeIconWhite from "../../assets/icons/hotspots/white/youtube.svg";

import FileIconBlack from "../../assets/icons/hotspots/black/file-text.svg";
import GalleryIconBlack from "../../assets/icons/hotspots/black/gallery.svg";
import ImageIconBlack from "../../assets/icons/hotspots/black/image.svg";
import ListIconBlack from "../../assets/icons/hotspots/black/list.svg";
import LinkIconBlack from "../../assets/icons/hotspots/black/link.svg";
import PlayIconBlack from "../../assets/icons/hotspots/black/play.svg";
import PlusIconBlack from "../../assets/icons/hotspots/black/plus.svg";
import YoutubeIconBlack from "../../assets/icons/hotspots/black/youtube.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as RegularIcons from '@fortawesome/free-regular-svg-icons';


const defaultIcons = {
   file: {
      black: FileIconBlack,
      white: FileIconWhite,
   },
   gallery: {
      black: GalleryIconBlack,
      white: GalleryIconWhite,
   },
   image: {
      black: ImageIconBlack,
      white: ImageIconWhite,
   },
   list: {
      black: ListIconBlack,
      white: ListIconWhite,
   },
   link: {
      black: LinkIconBlack,
      white: LinkIconWhite,
   },
   play: {
      black: PlayIconBlack,
      white: PlayIconWhite,
   },
   video: {
      black: PlayIconBlack,
      white: PlayIconWhite,
   },
   videoPopup: {
      black: PlayIconBlack,
      white: PlayIconWhite,
   },
   plus: {
      black: PlusIconBlack,
      white: PlusIconWhite,
   },
   text: {
      black: PlusIconBlack,
      white: PlusIconWhite,
   },
   youtube: {
      black: YoutubeIconBlack,
      white: YoutubeIconWhite,
   },
};

const fontAwesomeIcons = {
   festive: {
      black: <FontAwesomeIcon icon={SolidIcons.faGift} style={{color: "black"}} alt="Hotspot icon" className="content-fa-icon"/>,
      white: <FontAwesomeIcon icon={SolidIcons.faGift} style={{color: "white"}} alt="Hotspot icon" className="content-fa-icon"/>
   },
   animation: {
      black: <FontAwesomeIcon icon={RegularIcons["faHand"]} style={{color: "black"}} alt="Hotspot icon" className="content-fa-icon"/>,
      white: <FontAwesomeIcon icon={RegularIcons["faHand"]} style={{color: "white"}} alt="Hotspot icon" className="content-fa-icon"/>
   },

}



const isIconBuiltIn = (contentType) => {
   return defaultIcons[contentType] !== undefined;
}

const getBuiltInIcon = (contentType, useBlackIcon) =>
   defaultIcons[contentType][useBlackIcon ? "black" : "white"];

const getFontAwesomeIcon = (contentType, useBlackIcon) => {
   if(fontAwesomeIcons[contentType])
      return fontAwesomeIcons[contentType][useBlackIcon ? "black" : "white"];
   else
      return null;

}

const ContentIcon = ({ contentType, fontAwesomeIconName, fontAwesomeIconStyle, useBlackIcon = false }) => {
   if (fontAwesomeIconName) {
      try {
         return <FontAwesomeIcon
            icon={fontAwesomeIconStyle.toLowerCase() === "solid" ? SolidIcons[fontAwesomeIconName] : RegularIcons[fontAwesomeIconName]}
            style={{color: useBlackIcon ? "black" : "white"}}
            alt="Hotspot icon"
            className="content-fa-icon"
         />
      } catch (error) {
         return <></>
      }
   } else if (isIconBuiltIn(contentType)) {
      const icon = getBuiltInIcon(contentType, useBlackIcon)
      if(icon){
         return <img src={icon} alt="Hotspot icon" className="content-icon" />;
      } else return <></>
   } else if (contentType) {
      const icon = getFontAwesomeIcon(contentType, useBlackIcon)
         return icon ? icon : <></>;
   } else {
      return <></>
   }
};

export default ContentIcon;
