import { useState } from "react";
import { useConfig } from "../../../contexts/useConfig";

import ReactGA from "react-ga4";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import DisclaimerInfoIcon from "../../../assets/icons/info-icon.png";
import DisclaimerModal from "../../Modals/Disclaimer/DisclaimerModal";
import { useViewerMode } from "../../../contexts/useViewerMode";

export default function DisclaimerButton() {
   const [showModal, setShowModal] = useState(false);
   const [config] = useConfig();

   const [viewerMode, setViewerMode] = useViewerMode();


   function handleClick() {
      if (viewerMode === "select") {
         setViewerMode("view")
      }

      setShowModal(true);
      ReactGA.event({
         category: "Modal View",
         action: "reybeam_information",
      });
   }

   return (
      config.actions.disclaimer && config.disclaimerInformation.disclaimerMode !== "alwaysVisible" && (
         <>
            <ActionButtonStandard
               className="disclaimer-button"
               iconImg={DisclaimerInfoIcon}
               onClick={handleClick}
            />
            <DisclaimerModal canShow={showModal} updateModalState={setShowModal} />
         </>
      )
   );
}
