import { motion } from "framer-motion"
import "./ModelSelectorTitleBar.scss"
const ModelSelectorTitleBar = ({mouseOverThumbnail, productName, variantName}) => {
    
    const variants = {
        open: { opacity: 1, scale: 1, originX: 0.5, originY:1, translateY: "-1rem" },
        closed: { opacity: 0, scale: 0, originX: 0.5, originY:1 }
    }

    return (
       <motion.div
          variants={variants}
          animate={mouseOverThumbnail ? "open" : "closed"}
          transition={{ duration: 0.2}}
          className="model-selector-title-bar"
       >
          <div className="text-container">
             <div className="product-name">{productName}</div>
             <div className="variant-name">{variantName} </div>
          </div>
       </motion.div>
    );
}
 
export default ModelSelectorTitleBar;