import "./ModelTitle.scss";
import { useCurrentProduct } from "../../contexts/useCurrentProduct";
import { useCurrentVariant } from "../../contexts/useCurrentVariant";
import { useConfig } from "../../contexts/useConfig";
import {getPropertyOrDefault} from "../../helpers/helpers";


export default function ModelTitle() {
   const [currentProduct] = useCurrentProduct();
   const [currentVariant] = useCurrentVariant();
   const [config] = useConfig();

   const showTitle = getPropertyOrDefault(config["show-title"], false);
   const titleColor = getPropertyOrDefault(config["title-color"], "#555760");
   const titleOpacity = getPropertyOrDefault(config["title-opacity"], "100%");


   const setTitleColor = () => {
      if (showTitle) {
         document.documentElement.style.setProperty("--title-color", titleColor);
      }
   };

   const setTitleOpacity = () => {
      if (showTitle) {
         document.documentElement.style.setProperty("--title-opacity", titleOpacity);
      }
   };

   setTitleColor();
   setTitleOpacity();


   return (
      showTitle && (
         <div className="model-title">
            {currentProduct.name && (
               <>
                  <span className="product-name">{currentProduct.name}</span>
                  <span className="variant-name">{currentVariant["variant-name"] ?? currentVariant["variant_name"]}</span>
               </>
            )}
         </div>
      )
   );
}
