import "./App.scss";
import { VariantProvider } from "../contexts/useCurrentVariant";
import { ProductProvider } from "../contexts/useCurrentProduct";
import { ActionProvider } from "../contexts/useAction";
import { ConfigProvider } from "../contexts/useConfig";
import { ViewerModeProvider } from "../contexts/useViewerMode";
import { VideoModalProvider } from "../contexts/useVideoModal";
import { useGoogleAnalytics } from "../hooks/useGoogleAnalytics";
import { LocalSessionSettingsProvider } from "../contexts/useLocalSessionSettings";
import { useState } from "react";

import ModelViewer from "./ModelViewer/ModelViewer";
import VideoPopupContainer from "./Hotspot/VideoPopup/VideoPopupContainer";
import ViewerUI from "./ViewerUI/ViewerUI";
import { HotspotModalProvider } from "../contexts/useHotspotModal";
import HotspotModalPopup from "./Hotspot/HotspotModalPopup/HotspotModalPopup";

export default function App() {
   useGoogleAnalytics();
   const [uiVisible, setUIVisible] = useState(true);

   return (
      <ProductProvider>
         <VariantProvider>
            <LocalSessionSettingsProvider>
               <ViewerModeProvider>
                  <ActionProvider>
                     <ConfigProvider>
                        <HotspotModalProvider>
                        <VideoModalProvider>
                           <ModelViewer toggleUI={setUIVisible}>
                           </ModelViewer>
                              <ViewerUI uiVisible={uiVisible}/>
                              <VideoPopupContainer/>
                              <HotspotModalPopup />
                        </VideoModalProvider>
                        </HotspotModalProvider>
                     </ConfigProvider>
                  </ActionProvider>
               </ViewerModeProvider>
            </LocalSessionSettingsProvider>
         </VariantProvider>
      </ProductProvider>
   );
}
