import "./VideoContent.scss";
import { useRef, useEffect } from "react";

const FileUrlVideoHotspot = ({ videoUrl, hotspotActive, videoTextContent }) => {
   const videoFrameRef = useRef({ hotspotActive });

   useEffect(() => {
         if (hotspotActive) {
            videoFrameRef.current.play();
         } else {
            videoFrameRef.current.pause();
         }
      }, [hotspotActive]);

   return (
      <div>
         <div className="__video-content">
            <video 
            controls
            ref={videoFrameRef}>
               <source src={videoUrl} type="video/mp4" />
            </video>
         </div>
         <div className="modal__video-text-content">
            <p>{videoTextContent}</p>
         </div>
      </div>
   );
};

export default FileUrlVideoHotspot;
