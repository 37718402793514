import "./HandoffModal.scss";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";



import Modal from "../../Modals/Modal/Modal";
import CloseButton from "../../Buttons/Close/CloseButton";
import ActionButtonStandard from "../../Buttons/Action/ActionButtonStandard";
import { useCurrentProduct } from "../../../contexts/useCurrentProduct";
import { useCurrentVariant } from "../../../contexts/useCurrentVariant";

export default function HandoffModal() {
   const params = new URLSearchParams(window.location.search);
   const [showModal, setShowModal] = useState(params.has("handoff"));
   const modelViewer = document.querySelector("model-viewer");
   const {t} = useTranslation();
   const [currentProduct, ] = useCurrentProduct();
   const [currentVariant, ] = useCurrentVariant();



   const handleLaunchArClicked = () => {
      modelViewer.activateAR()
      ReactGA.event({
         category: "Augmented Reality",
         action: "AR Activated (Handoff Modal)",
         label: (currentProduct && currentVariant) ? currentProduct.name + "|" + currentVariant["variant_name"] ?? currentVariant["variant-name"] : null
      });

   }

   return (
      <Modal canShow={showModal} className="handoff-modal">
         <CloseButton closeModal={() => setShowModal(false)} />
         <h2 className="handoff-modal__title">{t("handoffModalTitle")}</h2>
         <p>{t("handoffModalContent")}</p>
         <ActionButtonStandard
            disabled={modelViewer.canActivateAR}
            label={t("handoffModalButton")}
            className="handoff-modal__button"
            onClick={handleLaunchArClicked}
         />
      </Modal>
   );
}
