import { useEffect, useState } from "react";
import LanguageSwitcherSelector from "./LanguageSwitcherSelector";
import { useTranslation } from 'react-i18next';
import { useConfig } from "../../../contexts/useConfig";
import { useViewerUrlRedirect } from "../../../hooks/useViewerUrlRedirect";

const LanguageSwitcher = () => {
    const [config,,languages] = useConfig();
    //const config = useConfig();
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState("en-GB");
    const redirect = useViewerUrlRedirect;

    const handleChangeLanguage = (languageCode) => {
        const configLanguage = languages.filter((l) => l.languageCode === languageCode)[0];
        console.log("configLanguage", configLanguage);
        if (configLanguage){
            console.log("config language selected", configLanguage)
            redirect(configLanguage.viewerUuid)
        }

        //setCurrentLanguage(languageCode);
        //changeLanguage(languageCode);
    }

    useEffect(() => {
        console.log("changeLanguage", currentLanguage);
        if (currentLanguage){
            i18n.changeLanguage(currentLanguage.split("-")[0]);
        }
    }, [currentLanguage, i18n])
    useEffect(() => {
        const configLanguage = languages.filter((l) => l.viewerUuid === config["uuid"])[0];
        console.log("configLanguage", configLanguage);
        if (configLanguage){
            setCurrentLanguage(configLanguage.languageCode)
        }
    }, [languages, config])

    return (
        <div className="languageSwitcher">
            <LanguageSwitcherSelector
                languages={languages}
                currentLanguage={currentLanguage}
                handleChangeLanguage={handleChangeLanguage}
            />
        </div>);
}

export default LanguageSwitcher;