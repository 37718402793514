import { useCurrentVariant } from "../contexts/useCurrentVariant";
import { useCurrentProduct } from "../contexts/useCurrentProduct";
import { useViewerMode } from "../contexts/useViewerMode";
import { useConfig } from "../contexts/useConfig";
import { buildQueryString, getCurrentQueryStringParameters } from "../helpers/helpers";

export function useShareUrl() {
   const [currentModel] = useCurrentVariant();
   const [currentProduct] = useCurrentProduct();
   const [viewerMode,] = useViewerMode();
   const [config] = useConfig();

   if (config && config.customShareUrl) {
      return config.customShareUrl;
   }

   const productIndex = config.products.findIndex(
      (product) => product.uuid === currentProduct.uuid
   );

   const variantIndex = config.products[productIndex].variants.findIndex(
      (variant) => variant.uuid === currentModel.uuid
   );

   const baseUrl = window.location.origin + window.location.pathname;

   let params = getCurrentQueryStringParameters();
   params = [...params, {
      key: "product",
      value: productIndex
   }, {
      key: "variant",
      value: variantIndex
   }, {
      key: "show_hotspots",
      value: viewerMode === "explore"
   }]

   const queryString = buildQueryString(params);

   return baseUrl + queryString;

}
