import { createContext, useContext, useState } from "react";

const hotspotModalContext = createContext();

export function useHotspotModal() {
   return useContext(hotspotModalContext);
}

export function HotspotModalProvider(props) {
    const [hotspotModalVisible, setHotspotModalVisible] = useState(false);
    const [hotspotData, setHotspotData] = useState(false);
    const [hotspotOptions, setHotspotOptions] = useState(false);
    const [hotspotHeadingColor, setHotspotHeadingColor] = useState(false);

   return (
      <hotspotModalContext.Provider value={{hotspotModalVisible, setHotspotModalVisible, hotspotData, setHotspotData, hotspotOptions, setHotspotOptions, hotspotHeadingColor, setHotspotHeadingColor}}>
        {props.children}
      </hotspotModalContext.Provider>
   );
}
