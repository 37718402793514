import "./ActionButton.scss";
import ReactGA from "react-ga4";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { useCurrentProduct } from "../../../contexts/useCurrentProduct";
import { useCurrentVariant } from "../../../contexts/useCurrentVariant";

const ActionButtonViewerState = (props) => {
   const [viewerMode, setViewerMode] = useViewerMode();
   const [currentProduct, ] = useCurrentProduct();
   const [currentVariant, ] = useCurrentVariant();

   function handleClick() {
        if(viewerMode === props.action) {
         setViewerMode("view");
      } else {
         setViewerMode(props.action);
      }

      ReactGA.event({
         category: "Action Button 3",
         action: `reybeam_${props.action}`.toLowerCase(),
         label: currentProduct["name"] + "|" + currentVariant["variant_name"] ?? currentVariant["variant-name"]
      });
   }

   return (
      <button
         className={
            "action-btn " +
            (props.active ? "action-btn-active " : "") +
            (props.className && props.className)
         }
         onClick={handleClick}
         disabled={props.disabled}
      >
         {props.iconImg && (
            <img src={props.iconImg} alt="icon" aria-hidden="true" className="action-btn__icon" />
         )}
         <span className="action-btn__label">{props.label}</span>
      </button>
   );
};

export default ActionButtonViewerState;
