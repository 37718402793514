import { useState } from "react";
import { useConfig } from "../../../contexts/useConfig";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as DimensionIcon } from "../../../assets/icons/measure_icon.svg";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { useLocalSessionSettings } from "../../../contexts/useLocalSessionSettings";

export default function DimensionButton() {
   const [config] = useConfig();
   const [viewerMode, setViewerMode] = useViewerMode();
   const [, updateSettings] = useLocalSessionSettings();
   const [buttonActive, setButtonActive] = useState(false);

   function handleClick() {
      if (viewerMode === "select") {
         setViewerMode("view");
      }

      const newButtonState = !buttonActive;
      setButtonActive(newButtonState);
      updateSettings({ showDimensions: newButtonState });
   }

   return (
      config.actions.dimension && (
         <ActionButtonStandard
            className={buttonActive ? "dimension-button highlight" : "dimension-button"}
            iconImg={<DimensionIcon />}
            useSvgIcon={true}
            onClick={handleClick}
         />
      )
   );
}
