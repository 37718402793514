import React, { useEffect, RefObject } from 'react';
import './Dimensions.css';

interface DimensionsProps {
  modelViewerRef: RefObject<any>;
  fullDisplayMode?: boolean;
  unit?: string;
  decimalPlaces?: number;
}

const Dimensions: React.FC<DimensionsProps> = ({ modelViewerRef, fullDisplayMode = false, unit = 'cm', decimalPlaces = 1}) => {
  useEffect(() => {
    const modelViewer = modelViewerRef.current;

    const drawLine = (svgLine: SVGLineElement, dotHotspot1: any, dotHotspot2: any, dimensionHotspot: any) => {
      if (dotHotspot1 && dotHotspot2) {
        svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x);
        svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y);
        svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x);
        svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y);

        if (dimensionHotspot && !dimensionHotspot.facingCamera) {
          svgLine.classList.add('hide');
        } else {
          svgLine.classList.remove('hide');
        }
      }
    };

    const renderSVG = () => {
      const lines = [
        ['hotspot-dot1', 'hotspot-dot2', 'hotspot-dim1'],
        ['hotspot-dot2', 'hotspot-dot3', 'hotspot-dim2'],
        ['hotspot-dot3', 'hotspot-dot4', 'hotspot-dim3'],
        ['hotspot-dot4', 'hotspot-dot1', 'hotspot-dim4'],
        ['hotspot-dot5', 'hotspot-dot6', 'hotspot-dim5'],
        ['hotspot-dot6', 'hotspot-dot7', 'hotspot-dim6'],
        ['hotspot-dot7', 'hotspot-dot8', 'hotspot-dim7'],
        ['hotspot-dot8', 'hotspot-dot5', 'hotspot-dim8'],
        ['hotspot-dot1', 'hotspot-dot5', 'hotspot-dim9'],
        ['hotspot-dot2', 'hotspot-dot6', 'hotspot-dim10'],
        ['hotspot-dot3', 'hotspot-dot7', 'hotspot-dim11'],
        ['hotspot-dot4', 'hotspot-dot8', 'hotspot-dim12'],
      ];
      
      lines.forEach(([dot1, dot2, dim], index) => {
        drawLine(
          modelViewer.querySelectorAll('line')[index] as SVGLineElement,
          modelViewer.queryHotspot(dot1),
          modelViewer.queryHotspot(dot2),
          modelViewer.queryHotspot(dim)
        );
        if (fullDisplayMode) {
          const oppositeDim = `${dim}-opposite`;
          drawLine(
            modelViewer.querySelectorAll('line')[index + 12] as SVGLineElement,
            modelViewer.queryHotspot(dot1),
            modelViewer.queryHotspot(dot2),
            modelViewer.queryHotspot(oppositeDim)
          );
        }
      });
    };

    const updateHotspots = () => {
      const center = modelViewer.getBoundingBoxCenter();
      const size = modelViewer.getDimensions();
      const x2 = size.x / 2;
      const y2 = size.y / 2;
      const z2 = size.z / 2;

      const positions = {
        'hotspot-dot1': `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
        'hotspot-dot2': `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
        'hotspot-dot3': `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
        'hotspot-dot4': `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
        'hotspot-dot5': `${center.x + x2} ${center.y + y2} ${center.z + z2}`,
        'hotspot-dot6': `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
        'hotspot-dot7': `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
        'hotspot-dot8': `${center.x - x2} ${center.y + y2} ${center.z + z2}`,
      };

      Object.keys(positions).forEach((key) => {
        modelViewer.updateHotspot({ name: key, position: positions[key] });
      });

      const midpoints = [
        { name: 'hotspot-dim1', value: size.z, position: `${center.x + x2} ${center.y - y2} ${center.z}` },
        { name: 'hotspot-dim2', value: size.x, position: `${center.x} ${center.y - y2} ${center.z - z2}` },
        { name: 'hotspot-dim3', value: size.z, position: `${center.x - x2} ${center.y - y2} ${center.z}` },
        { name: 'hotspot-dim4', value: size.x, position: `${center.x} ${center.y - y2} ${center.z + z2}` },
        { name: 'hotspot-dim5', value: size.z, position: `${center.x + x2} ${center.y + y2} ${center.z}` },
        { name: 'hotspot-dim6', value: size.x, position: `${center.x} ${center.y + y2} ${center.z - z2}` },
        { name: 'hotspot-dim7', value: size.z, position: `${center.x - x2} ${center.y + y2} ${center.z}` },
        { name: 'hotspot-dim8', value: size.x, position: `${center.x} ${center.y + y2} ${center.z + z2}` },
        { name: 'hotspot-dim9', value: size.y, position: `${center.x + x2} ${center.y} ${center.z + z2}` },
        { name: 'hotspot-dim10', value: size.y, position: `${center.x + x2} ${center.y} ${center.z - z2}` },
        { name: 'hotspot-dim11', value: size.y, position: `${center.x - x2} ${center.y} ${center.z - z2}` },
        { name: 'hotspot-dim12', value: size.y, position: `${center.x - x2} ${center.y} ${center.z + z2}` },
      ];

      midpoints.forEach((dim) => {
        const text = formatDimension(dim.value * 1000, unit, decimalPlaces);
        modelViewer.updateHotspot({ name: dim.name, position: dim.position });
        modelViewer.querySelector(`button[slot="${dim.name}"]`).textContent = text;
        if (fullDisplayMode) {
          const oppositeDim = `${dim.name}-opposite`;
          modelViewer.updateHotspot({ name: oppositeDim, position: dim.position });
          const oppositeElement = modelViewer.querySelector(`button[slot="${oppositeDim}"]`);
          oppositeElement.textContent = text;
          oppositeElement.classList.add('backface');
        }
      });

      renderSVG();
      modelViewer.addEventListener('camera-change', () => {
        renderSVG();
        updateVisibility();
      });
    };

    const calculateDistance = (positionString: string) => {
      const [x, y, z] = positionString.split(' ').map(parseFloat);
      const cameraOrbit = modelViewer.getCameraOrbit();
      const cameraPosition = {
        x: Math.sin(cameraOrbit.theta) * Math.cos(cameraOrbit.phi),
        y: Math.sin(cameraOrbit.phi),
        z: Math.cos(cameraOrbit.theta) * Math.cos(cameraOrbit.phi),
      };

      return Math.sqrt(
        (x - cameraPosition.x) ** 2 +
        (y - cameraPosition.y) ** 2 +
        (z - cameraPosition.z) ** 2
      );
    };

    const updateVisibility = () => {
      const dimensions = [
        'hotspot-dim1',
        'hotspot-dim2',
        'hotspot-dim3',
        'hotspot-dim4',
        'hotspot-dim5',
        'hotspot-dim6',
        'hotspot-dim7',
        'hotspot-dim8',
        'hotspot-dim9',
        'hotspot-dim10',
        'hotspot-dim11',
        'hotspot-dim12',
      ];

      const distances = dimensions.map((dim) => {
        const hotspot = modelViewer.queryHotspot(dim);
        if (hotspot) {
          const dist = calculateDistance(hotspot.position.toString());
          return { dim, dist };
        }
        return { dim, dist: Infinity };
      });

      const closestX = distances
        .filter(d => d.dim.includes('dim2') || d.dim.includes('dim4') || d.dim.includes('dim6') || d.dim.includes('dim8'))
        .sort((a, b) => a.dist - b.dist)[0]?.dim;

      const closestZ = distances
        .filter(d => d.dim.includes('dim1') || d.dim.includes('dim3') || d.dim.includes('dim5') || d.dim.includes('dim7'))
        .sort((a, b) => a.dist - b.dist)[0]?.dim;

      const closestY = distances
        .filter(d => d.dim.includes('dim9') || d.dim.includes('dim10') || d.dim.includes('dim11') || d.dim.includes('dim12'))
        .sort((a, b) => a.dist - b.dist)[0]?.dim;

      dimensions.forEach((dim) => {
        if (dim === closestX || dim === closestZ || dim === closestY) {
          modelViewer.querySelector(`button[slot="${dim}"]`).classList.remove('hide');
        } else {
          modelViewer.querySelector(`button[slot="${dim}"]`).classList.add('hide');
        }
        if (fullDisplayMode) {
          const oppositeDim = `${dim}-opposite`;
          if (dim === closestX || dim === closestZ || dim === closestY) {
            modelViewer.querySelector(`button[slot="${oppositeDim}"]`).classList.remove('hide');
          } else {
            modelViewer.querySelector(`button[slot="${oppositeDim}"]`).classList.add('hide');
          }
        }
      });
    };

    // updateHotspots();
    // updateVisibility();

    modelViewer.addEventListener('load', () => {
      updateHotspots();
      updateVisibility();
    });

    return () => {
      modelViewer.removeEventListener('load', updateHotspots);
      modelViewer.removeEventListener('camera-change', renderSVG);
    };
  }, [modelViewerRef, fullDisplayMode, unit, decimalPlaces]);

  // useEffect(() => {
    
  // }, [])

  return (
    <>
      <button slot="hotspot-dot1" className="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
      <button slot="hotspot-dot2" className="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
      <button slot="hotspot-dot3" className="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
      <button slot="hotspot-dot4" className="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
      <button slot="hotspot-dot5" className="dot" data-position="1 1 1" data-normal="1 0 0"></button>
      <button slot="hotspot-dot6" className="dot" data-position="1 1 -1" data-normal="1 0 0"></button>
      <button slot="hotspot-dot7" className="dot" data-position="-1 1 -1" data-normal="-1 0 0"></button>
      <button slot="hotspot-dot8" className="dot" data-position="-1 1 1" data-normal="-1 0 0"></button>
      <button slot="hotspot-dim1" className="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
      <button slot="hotspot-dim2" className="dim" data-position="0 -1 -1" data-normal="0 1 0"></button>
      <button slot="hotspot-dim3" className="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
      <button slot="hotspot-dim4" className="dim" data-position="0 -1 1" data-normal="0 1 0"></button>
      <button slot="hotspot-dim5" className="dim" data-position="1 1 0" data-normal="1 0 0"></button>
      <button slot="hotspot-dim6" className="dim" data-position="0 1 -1" data-normal="0 1 0"></button>
      <button slot="hotspot-dim7" className="dim" data-position="-1 1 0" data-normal="-1 0 0"></button>
      <button slot="hotspot-dim8" className="dim" data-position="0 1 1" data-normal="0 1 0"></button>
      <button slot="hotspot-dim9" className="dim" data-position="1 0 1" data-normal="1 0 0"></button>
      <button slot="hotspot-dim10" className="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
      <button slot="hotspot-dim11" className="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
      <button slot="hotspot-dim12" className="dim" data-position="-1 0 1" data-normal="-1 0 0"></button>

      {fullDisplayMode && (
        <>
          <button slot="hotspot-dim1-opposite" className="dim backface" data-position="1 -1 0" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim2-opposite" className="dim backface" data-position="0 -1 -1" data-normal="0 -1 0"></button>
          <button slot="hotspot-dim3-opposite" className="dim backface" data-position="-1 -1 0" data-normal="1 0 0"></button>
          <button slot="hotspot-dim4-opposite" className="dim backface" data-position="0 -1 1" data-normal="0 -1 0"></button>
          <button slot="hotspot-dim5-opposite" className="dim backface" data-position="1 1 0" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim6-opposite" className="dim backface" data-position="0 1 -1" data-normal="0 -1 0"></button>
          <button slot="hotspot-dim7-opposite" className="dim backface" data-position="-1 1 0" data-normal="1 0 0"></button>
          <button slot="hotspot-dim8-opposite" className="dim backface" data-position="0 1 1" data-normal="0 -1 0"></button>
          <button slot="hotspot-dim9-opposite" className="dim backface" data-position="1 0 1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim10-opposite" className="dim backface" data-position="1 0 -1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim11-opposite" className="dim backface" data-position="-1 0 -1" data-normal="1 0 0"></button>
          <button slot="hotspot-dim12-opposite" className="dim backface" data-position="-1 0 1" data-normal="1 0 0"></button>
        </>
      )}
      <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        <line className="dimensionLine"></line>
        {fullDisplayMode && (
          <>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
            <line className="dimensionLine backface"></line>
          </>
        )}
      </svg>
    </>
  );
};

export default Dimensions;

const formatDimension = (value: number, unit: string, decimalPlaces: number) => {
  const mmPerYard = 914.4;
  const mmPerFoot = 304.8;
  const mmPerInch = 25.4;

  const convertedValue = value; // since value is in mm

  switch (unit) {
    case 'mm':
      return `${convertedValue.toFixed(decimalPlaces)} ${unit}`;
    case 'cm':
      return `${(convertedValue / 10).toFixed(decimalPlaces)} ${unit}`;
    case 'm':
      return `${(convertedValue / 1000).toFixed(decimalPlaces)} ${unit}`;
    case 'in':
      return `${(convertedValue / mmPerInch).toFixed(decimalPlaces)} ${unit}`;
    case 'ft+in': {
      const feet = Math.floor(convertedValue / mmPerFoot);
      const inches = ((convertedValue % mmPerFoot) / mmPerInch).toFixed(decimalPlaces);
      return `${feet} ft ${inches} in`;
    }
    case 'yd+ft+in': {
      const yards = Math.floor(convertedValue / mmPerYard);
      const remainderAfterYards = convertedValue % mmPerYard;
      const feet = Math.floor(remainderAfterYards / mmPerFoot);
      const inches = ((remainderAfterYards % mmPerFoot) / mmPerInch).toFixed(decimalPlaces);
      return `${yards} yd ${feet} ft ${inches} in`;
    }
    default:
      return `${convertedValue.toFixed(decimalPlaces)} ${unit}`;
  }
};
