import { useConfig } from "../../contexts/useConfig";
import "./Watermark.scss";

export default function Watermark() {
   const [config] = useConfig();

   return (
      config["show-watermark"] && (
         <a href="https://reydar.com" target="_blank" rel="noreferrer" className="watermark">
            <svg x="0px" y="0px" viewBox="0 0 209.73 34.71">
               <path d="M19.1,34.71l-4.79-10.93h-3.8v10.93H0V0.01h18.52c8.12,0,12.49,5.41,12.49,11.92c0,5.98-3.49,9.16-6.24,10.51 l6.3,12.28H19.1z M20.34,11.87c0-1.87-1.67-2.71-3.43-2.71h-6.4v5.46h6.4C18.68,14.63,20.34,13.8,20.34,11.87z"></path>
               <path d="M35.28,34.71V0.01h26.33v9.16H45.79v3.43h15.45v9.16H45.79v3.8h15.82v9.16H35.28z"></path>
               <path d="M75.55,34.71V20.92L62.55,0.01h11.81l6.45,11.71l6.35-11.71h11.81l-12.9,20.92v13.79H75.55z"></path>
               <path d="M99.8,34.71V0.01h15.51c10.93,0,19.04,6.24,19.04,17.33s-8.12,17.38-18.99,17.38H99.8z M123.69,17.33 c0-4.68-2.71-8.17-8.33-8.17h-5.05v16.39h4.99C120.56,25.56,123.69,21.71,123.69,17.33z"></path>
               <path d="M159.69,34.71l-1.25-4.06h-12.49l-1.25,4.06h-11.92l12.8-34.71h13.22l12.8,34.71H159.69z M152.2,10.31 l-3.43,11.19h6.87L152.2,10.31z"></path>
               <path d="M192.58,34.71l-4.79-10.93H184v10.93h-10.51V0.01h18.52c8.12,0,12.49,5.41,12.49,11.92 c0,5.98-3.49,9.16-6.24,10.51l6.3,12.28H192.58z M193.83,11.87c0-1.87-1.67-2.71-3.43-2.71H184v5.46h6.4 C192.16,14.63,193.83,13.8,193.83,11.87z"></path>
               <path d="M206.66,6.14c-1.69,0-3.07-1.38-3.07-3.07c0-1.71,1.38-3.07,3.07-3.07c1.71,0,3.07,1.36,3.07,3.07 C209.73,4.76,208.37,6.14,206.66,6.14z M206.66,0.51c-1.42,0-2.56,1.13-2.56,2.56c0,1.4,1.14,2.56,2.56,2.56s2.56-1.16,2.56-2.56 C209.22,1.64,208.08,0.51,206.66,0.51z M207.48,4.84l-0.91-1.39h-0.56v1.39h-0.54V1.32h1.43c0.59,0,1.19,0.42,1.19,1.07 c0,0.78-0.69,1.04-0.9,1.04l0.94,1.4H207.48z M206.9,1.77h-0.9v1.22h0.9c0.3,0,0.64-0.23,0.64-0.59 C207.54,2,207.21,1.77,206.9,1.77z"></path>
            </svg>
         </a>
      )
   );
}
