export function getPropertyOrDefault(property, defaultValue) {
    if(property === undefined) {
        return defaultValue;
    } else {
        return property;
    }

};

export function getCurrentQueryStringParameters() {
    const query = window.location.search.substring(1);
    const parameters = query.split("&");
    const pairs = parameters.map((parameter) => parameter.split("="));
    const params = [];
    for (let pair of pairs) {
       const param = {
          key: pair[0],
          value: pair[1]
       }
       params.push(param);
    }

    return params;
}

export function buildQueryString(parameters) {
    let newQueryString = "?";
    parameters.forEach((param) => (newQueryString += `${param.key}=${param.value}&`));
    newQueryString = newQueryString.substring(0, newQueryString.length - 1);
    return newQueryString;
}