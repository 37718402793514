import "./VideoPopup.scss"
import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react"
import { useVideoModal } from "../../../contexts/useVideoModal";
import CloseIcon from "../../../assets/icons/close.png";
//import youtubeEmbed from "youtube-embed"
import YouTubePlayer from "youtube-player"

//import FullscreenIcon from "../../../assets/icons/fullscreen.png";


const YoutubeVideoPopup = () => {
   const {videoModalVisible, setVideoModalVisible, videoModalUrl, videoModalTitle, videoTextContent, setVideoTextContent, videoAutoplay, closeOnVideoEnded} = useVideoModal(); //the extra commas are here for a reason. actually valid syntax... amazes me too.
   const splitVideoTextContent = (videoTextContent ?? "").split('\n');

   const videoFrameRef = useCallback(node => {

      if(node) {
         const splitVideoUrl = videoModalUrl.split("=");
         const videoId = splitVideoUrl[splitVideoUrl.length - 1];
         const youtubePlayer = YouTubePlayer('video-player');
         youtubePlayer.loadVideoById(videoId);
         if(videoAutoplay) {
            youtubePlayer.playVideo();
         }

         if (youtubePlayer && closeOnVideoEnded) {
            youtubePlayer.on('stateChange', (event) => {
               if(event.data === 0){
                  setVideoTextContent("")
                  setVideoModalVisible(false);
               }
            });
         }

      async function resizeVideo() {
         const videoPlayerElement = document.querySelector(".modal__video-style");

        const clientWidth = videoPlayerElement.clientWidth;
        const ratio = 9/16;
        const videoHeight = ratio * clientWidth;
        videoPlayerElement.style.height = videoHeight+"px";
            
      }

      resizeVideo();
      window.addEventListener("resize", resizeVideo)
      }
      else{
         const elem = document.querySelector(".modal__video-style");
         const width = elem.clientWidth;
         const height = (9/16) * width;
         elem.style.height = height+"px";
      }

   }, [videoAutoplay, videoModalUrl, closeOnVideoEnded, setVideoTextContent, setVideoModalVisible])
   //const embedUrl = youtubeEmbed(videoModalUrl)
   //console.log(videoModalUrl, embedUrl)

   const closeVideo = () => {
      setVideoTextContent("")
      setVideoModalVisible(false);
   }

   return (
      <AnimatePresence>
         {videoModalVisible &&
            <div className="pop-container">
               <section className="modal__bg" onClick={() => {
                  setVideoModalVisible(false);
               }}>
                  <motion.div className="modal__align"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={(event)=>{event.stopPropagation();}}>
                     <motion.div className="modal__content"
                     onClick={()=>{}}
                     transition={{ type: "spring" }}
                     initial={{ scale: 0 }}
                     animate={{ scale: 1 }}
                     exit={{ scale: 0 }}>
                        <div className="modal__video-align">
                           <div className="modal__title-bar">
                              <h2>{videoModalTitle}</h2>
                              <div className="modal__title-bar__buttons">
                                 {/* <div
                                    className="modal__close action-btn button--fullscreen"
                                    onClick={() => {
                                       player.requestFullscreen();
                                    }}><img src={FullscreenIcon} alt="icon" aria-hidden="true" className="action-btn__icon" />
                                 </div> */}
                                 <div
                                    className="modal__close action-btn button--close"
                                    onClick={closeVideo}><img src={CloseIcon} alt="icon" aria-hidden="true" className="action-btn__icon" />
                                 </div>
                              </div>
                           </div>
                              <div 
                              id='video-player'
                              className="modal__video-style"
                              ref={videoFrameRef}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              >
                              </div>
                           <div className="modal__video-text-content">
                              <p>{splitVideoTextContent.map((line, index) => <span key={index}>{line}<br/></span>)}</p>
                           </div>
                        </div>
                     </motion.div>
                  </motion.div>
               </section>
            </div>
         } </AnimatePresence>
   )
};

export default YoutubeVideoPopup;
