import { buildQueryString, getCurrentQueryStringParameters } from "../helpers/helpers";

export function useViewerUrlRedirect(newConfigUuid, keepQueryStringParams=true) {
   /*switch(process.env.REACT_APP_ENV) {
       case "production":
          baseUrl = "https://reybeam.reydar.com";
          break;
       case "staging":
          baseUrl = "https://staging.reybeam.reydar.com";
          break;
       case "development":
          baseUrl = "https://dev.reybeam.reydar.com";
          break;
       default:
          break;
    }*/

   const baseUrl = window.location.origin + window.location.pathname;

   let url;

   if (keepQueryStringParams) {
      const params = getCurrentQueryStringParameters();      
      
      const configUuidPairIndex = params.findIndex((param) => param.key.toLowerCase() === "config_uuid");
      const configUuidPair = params[configUuidPairIndex];
      if (configUuidPair) {
         configUuidPair.value = newConfigUuid;
         params[configUuidPairIndex] = configUuidPair;
      }

      const newQueryString = buildQueryString(params);
   
      url = baseUrl + newQueryString;
      console.log(baseUrl, params, newQueryString)
      window.location.assign(url);

   
   } else {
      url = `${baseUrl}?config_uuid=${newConfigUuid}`;
      window.location.assign(url);
      return;
   }
}
