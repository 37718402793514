import { createContext, useContext, useState, useEffect } from "react";
import { useCurrentProduct } from "./useCurrentProduct";
import { useCurrentVariant } from "./useCurrentVariant";
import { useViewerMode } from "./useViewerMode";
import {getPropertyOrDefault} from "../helpers/helpers";


const configContext = createContext();

export function useConfig() {
   return useContext(configContext);
}

export function ConfigProvider(props) {
   const [config, setConfig] = useState();
   const [loading, setLoading] = useState(true);
   const [languages, setLanguages] = useState();
   const [, setCurrentProduct] = useCurrentProduct();
   const [, setCurrentModel] = useCurrentVariant();
   const [, setViewerMode] = useViewerMode();

   const params = new URLSearchParams(window.location.search);
   const documentRoot = document.documentElement;
   const defaultProductIndex = params.get("product") ? params.get("product") : 0;
   const defaultVariantIndex = params.get("variant") ? params.get("variant") : 0;

   let baseUrl;
   switch(process.env.REACT_APP_ENV) {
      case "production":
         baseUrl = "https://cdn.enginecloud.co.uk";
         break;
      case "staging":
         baseUrl = "https://cdn.enginecloud.co.uk/staging";
         break;
      case "development":
         baseUrl = "https://cdn.enginecloud.co.uk/dev";
         break;
      default:
         break;
   } 
   const configLocation = params.get("config_uuid")
      ? `${baseUrl}/viewer/configs/${params.get("config_uuid")}.json`
      : "default-config.json";

   function fetchConfigData() {
      fetch(configLocation)
         .then((response) => response.json())
         .then((data) => {
            setConfig(data);
            setViewerTitle(data["viewerTitle"]);
            setRegionLanguages(getPropertyOrDefault(data["regionLanguages"], []));
            setBrandColor(data["brand-color"]);
            setHotspotColor(data["hotspot-color"]);
            setBackgroundColor(getPropertyOrDefault(data["background-color"], "#ffffff"));
            setDefaultViewerMode(data);
            setCurrentProduct(data.products[defaultProductIndex]);
            setCurrentModel(data.products[defaultProductIndex].variants[defaultVariantIndex]);
         })
         .then(() => setLoading(false));
   }

   function setViewerTitle(viewerTitle) {
      
      if(viewerTitle) {
         document.title = viewerTitle
      }
      else {
         document.title = `Reydar - Model Viewer`
      }
   }

   function setDefaultViewerMode(data) {
      if(params.has("show_hotspots")) {
         setViewerMode("explore")
      } else {
         setViewerMode(getPropertyOrDefault(data["default-action"], "view"));
      }
   }
   
   function setRegionLanguages(languages) {
      setLanguages(languages);
   }

   function setBrandColor(color) {
      documentRoot.style.setProperty("--brand-color", color);
   }

   function setHotspotColor(color) {
      documentRoot.style.setProperty("--hotspot-color", color);
   }

   function setBackgroundColor(color) {
      documentRoot.style.setProperty("--background-color", color);
   }

   useEffect(() => {
      fetchConfigData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      !loading && (
         <configContext.Provider value={[config, setConfig, languages]}>
            {props.children}
         </configContext.Provider>
      )
   );
}
