import { useState } from "react";
import { useConfig } from "../../../contexts/useConfig";

import ReactGA from "react-ga4";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import ContactIcon from "../../../assets/icons/contact.png";
import ContactModal from "../../Modals/Contact/ContactModal";
import { useViewerMode } from "../../../contexts/useViewerMode";

export default function ContactButton() {
   const [showModal, setShowModal] = useState(false);
   const [config] = useConfig();
   const [viewerMode, setViewerMode] = useViewerMode();



   function handleClick() {
      if (viewerMode === "select") {
         setViewerMode("view")
      }

      setShowModal(true);
      ReactGA.event({
         category: "Modal View",
         action: "reybeam_contact",
      });
   }

   return (
      config.actions.contact && (
         <>
            <ActionButtonStandard
               className={(config.contact.pulse ? ("pulser ") : ("")) + "contact-button"}
               iconImg={ContactIcon}
               onClick={handleClick}
            />
            <ContactModal canShow={showModal} updateModalState={setShowModal} />
         </>
      )
   );
}
