import { useCurrentVariant } from "../../../contexts/useCurrentVariant";
import { useCurrentProduct } from "../../../contexts/useCurrentProduct";
import { getVariantUrl } from "../../../hooks/getVariantUrl";
import { motion } from "framer-motion"
import { useState } from "react"


import "./ModelButton.scss";
import ModelSelectorTitleBar from "../../ModelSelector/ModelSelectorTitleBar/ModelSelectorTitleBar";

export default function ModelButton({ model, className, product, onModelSelected }) {
   const [, setCurrentVariant] = useCurrentVariant();
   const [, setCurrentProduct] = useCurrentProduct();
   const [variantName] = useState(model["variant-name"] ?? model["variant_name"]);
   const [mouseOverThumbnail, setMouseOverThumbnail] = useState();

   const thumbnailSrc = getVariantUrl(product.uuid, model.thumbnail);


   const handleMouseEnter = (event) => {
      setMouseOverThumbnail(true);
   }
   const handleMouseLeave = (event) => {
      setMouseOverThumbnail(false);
   }
   const handleClick = (event) => {
      removeAllActiveStates();
      setCurrentProduct(product);
      setCurrentVariant(model);
      event.currentTarget.classList.add("active");
      onModelSelected(model);
   };
   

   const removeAllActiveStates = () => {
      const modelButtons = document.querySelectorAll(".btn-product");
      modelButtons.forEach((button) => button.classList.remove("active"));
   };

   return (
      <motion.li
      whileHover={{
         scale: 1.05,
         transition: { duration: 0.2 },
       }}
      >
         <button className={`btn-product ${className}`} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src={thumbnailSrc} alt="Product Button" draggable="false"></img>
            <div className="product-info">
               <div><span className="product-name">{product.name}</span></div>
               <div><span className="variant-name">{variantName}</span></div>
            </div>
            <ModelSelectorTitleBar mouseOverThumbnail={mouseOverThumbnail} productName={product.name} variantName={variantName}></ModelSelectorTitleBar>
         </button>
      </motion.li>
   );
}
