import "../ActionBar/ActionBar.scss";

import ViewButton from "../Buttons/View/ViewButton";
import SelectButton from "../Buttons/Select/SelectButton";
import ExploreButton from "../Buttons/Explore/ExploreButton";
import BuyButton from "../Buttons/Buy/BuyButton";
import ArButton from "../Buttons/Ar/ArButton";


const ActionButtonContainer = (props) => {
   if ("isVisible" in props && !props.isVisible) {
      return null;
   }
   return (
      <div className={"action-bar-list-container " + props.deviceType}>
         <div className={"action-bar-list " + props.deviceType}>
            <ViewButton />
            <SelectButton />
            <ExploreButton />
            <BuyButton />
            <ArButton />
         </div>
      </div>
   );
}

export default ActionButtonContainer;