import { useState } from "react";

const LanguageSwitcherSelector = ({languages, currentLanguage, handleChangeLanguage}) => {

  const [mouseHover, setMouseHover] = useState(false);


  const handleMouseEnter = () => {
    setMouseHover(true);
  }

  const handleMouseLeave = () => {
    setMouseHover(false);
  }
   
  const getLanguages = () =>{
    const filteredLangs = languages.filter(lang => lang.languageCode !== currentLanguage)
    const selectedLang = languages.filter(lang => lang.languageCode === currentLanguage)
    return [...selectedLang, ...filteredLangs];
  }
   return (
   <div className="lang" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> 
    <div 
      className="languageOptions firstElement" style={{backgroundImage:"url(../../../assets/regionflags/" + currentLanguage.split("-")[1] + "_64.png)"}}
    ></div>
    <div className="dropdown dropdown-background">
      {mouseHover && getLanguages()
            .map(language => 
                <div 
                key={language.languageCode} 
                value={language.languageCode} 
                className={"languageOptions" + (language.languageCode === currentLanguage ? " loCurrent" : "")} style={{backgroundImage:"url(../../../assets/regionflags/" + language.languageCode.split("-")[1] + "_64.png)"}}
                onClick={() => handleChangeLanguage(language.languageCode)}
                >                    
                    {language.languageCode === currentLanguage ? (<div className="loCurrentFilter"></div>) : ("")}
            </div>)
        }
    </div>


    

  </div>  );
}
 
export default LanguageSwitcherSelector;