import { useConfig } from "../../contexts/useConfig";
import CustomLogo from "../CustomLogo/CustomLogo";
import {getPropertyOrDefault} from "../../helpers/helpers";


const CustomLogosContainer = ({fadeBackground, fadeBackgroundOpacity}) => {
    const [config] = useConfig();
    const customLogos = getPropertyOrDefault(config["customLogos"], []);
    return (
    <div className={`custom-logo-container`} style={{filter:`brightness(${fadeBackground ? 1 - fadeBackgroundOpacity : 1})`}}>
        {customLogos.map((logoData) => <CustomLogo key={logoData.id} logoData={logoData} />)}
    </div>
    )
}
 
export default CustomLogosContainer;