import "./PosterImage.scss";
const PosterImage = (props) => {
   return (
      <div
         id="lazy-load-poster"
         slot="poster"
         style={{ backgroundImage: `url(${props.posterImageSrc})` }}
      >
         <div className="poster-content">
            <div className="poster-tip">
               <p>Click to interact with 3D model</p>
            </div>
         </div>
      </div>
   );
};

export default PosterImage;
