import "./ImageContent.scss";
import isMobile from "is-mobile";

const ImageContent = ({ content }) => {
   const image = content.imageUrl;
   const mobileImage = content.mobileImageUrl;
   const imageSource = (isMobile() && mobileImage) ? mobileImage : image;

   return (
      <div className="image-container">
         <img className={(isMobile() ? "single-image mobile" : "single-image")}
            src={imageSource}
            alt={content.imageAlt}
         />
         <p>{content.imageCaption}</p>
      </div>
   );
};

export default ImageContent;