import { createContext, useContext, useState } from "react";

const videoModalContext = createContext();

export function useVideoModal() {
   return useContext(videoModalContext);
}

export function VideoModalProvider(props) {
   const [videoModalUrl, setVideoModalUrl] = useState();
   const [videoModalTitle, setVideoModalTitle] = useState();
   const [videoModalVisible, setVideoModalVisible] = useState(false);
   const [videoTextContent, setVideoTextContent] = useState(false);
   const [videoModalSource, setVideoModalSource] = useState();
   const [videoAutoplay, setVideoAutoplay] = useState(true);
   const [closeOnVideoEnded, setCloseOnVideoEnded] = useState(false);

   return (
      <videoModalContext.Provider value={{videoModalVisible, setVideoModalVisible, videoModalUrl, setVideoModalUrl, videoModalTitle, setVideoModalTitle, videoModalSource, setVideoModalSource, videoTextContent, setVideoTextContent, videoAutoplay, setVideoAutoplay, closeOnVideoEnded, setCloseOnVideoEnded}}>        {props.children}
      </videoModalContext.Provider>
   );
}
