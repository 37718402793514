import { createContext, useContext, useEffect, useState } from "react";
import isMobile from "is-mobile";

const localSessionSettingsContext = createContext();

export function useLocalSessionSettings() {
   return useContext(localSessionSettingsContext);
}

export function LocalSessionSettingsProvider(props) {
   const initialSettings = {
      autoRotateAvailable: true,
      isMobile: isMobile(),
      showDimensions: false
   }
   const [localSessionSettings, setLocalSessionSettings] = useState(initialSettings);

   const updateSettings = (settingsToUpdate) => {
      setLocalSessionSettings(prev => {return {...prev, ...settingsToUpdate}})
   }

   useEffect(() => {
      console.log(localSessionSettings)
   } ,[localSessionSettings])

   return (
      <localSessionSettingsContext.Provider value={[localSessionSettings, updateSettings]}>
         {props.children}
      </localSessionSettingsContext.Provider>
   );
}
