import VimeoVideoPopup from "./VimeoVideoPopup";
import YoutubeVideoPopup from "./YoutubeVideoPopup";
import { useVideoModal } from "../../../contexts/useVideoModal";
import FileUrlVideoPopup from "./FileUrlVideoPopup";


const VideoPopupContainer = () => {
    const {videoModalSource} = useVideoModal();
    if (!videoModalSource) {
        return <></>
    }
    switch (videoModalSource.toLowerCase()) {
        case "vimeo":
            return <VimeoVideoPopup/>
        case "youtube":
            return <YoutubeVideoPopup/>
        case "fileurl":
            return <FileUrlVideoPopup/>
        default:
            return<></>
    }
}
 

export default VideoPopupContainer;