import "./VideoContent.scss";
import { useState, useEffect, useCallback } from "react"
import YouTubePlayer from "youtube-player"



const YoutubeVideoHotspot = ({ videoUrl, hotspotActive, videoTextContent }) => {
   const [youtubePlayer, setYoutubePlayer] = useState();
   const videoFrameRef = useCallback(node => {

      if(node) {
   
         const splitVideoUrl = videoUrl.split("=");
         const videoId = splitVideoUrl[splitVideoUrl.length - 1];
         const newYoutubePlayer = new YouTubePlayer('video-player');
         newYoutubePlayer.loadVideoById(videoId);
         if (!youtubePlayer) {
            setYoutubePlayer(newYoutubePlayer);
         }
      }
   }, [videoUrl, youtubePlayer]
   );
   
      useEffect(() => {
         if (youtubePlayer) {
            if (hotspotActive) {
               youtubePlayer.playVideo();
            } else {
               youtubePlayer.pauseVideo();
            }
         }
      }, [hotspotActive, youtubePlayer]);

   return (
      <div>
         <div>
         <div 
            id='video-player'
            className="modal__video-style"
            ref={videoFrameRef}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            >
         </div>
         <div className="modal__video-text-content">
            <p>{videoTextContent}</p>
         </div>
      </div>
      </div>
   );
};

export default YoutubeVideoHotspot;
