import { useState } from "react";
import { useConfig } from "../../../contexts/useConfig";
import { useTranslation } from 'react-i18next';

import ReactGA from "react-ga4";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import QrCodeModal from "../../Modals/QrCode/QrCodeModal";
import ArIcon from "../../../assets/icons/ar.png";
import {getPropertyOrDefault} from "../../../helpers/helpers";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { useCurrentProduct } from "../../../contexts/useCurrentProduct";
import { useCurrentVariant } from "../../../contexts/useCurrentVariant";

export default function ArButton() {
   const {t} = useTranslation();
   const [config] = useConfig();
   const [showModal, setShowModal] = useState();
   const showActionIcons = getPropertyOrDefault(config["show-action-icons"], true);

   const [viewerMode, setViewerMode] = useViewerMode();
   const [currentProduct] = useCurrentProduct();
   const [currentVariant] = useCurrentVariant();


   function handleClick() {
      const modelViewer = document.querySelector("model-viewer");

      if (modelViewer.canActivateAR) {
         modelViewer.cameraOrbit = "0deg 75deg 105%";
         modelViewer.cameraTarget = "auto auto auto";
         modelViewer.resetTurntableRotation();
         setTimeout(() => modelViewer.activateAR(), 500);

         ReactGA.event({
            category: "Augmented Reality",
            action: "AR Activated (Action Bar)",
            label: currentProduct["name"] + "|" + currentVariant["variant_name"] ?? currentVariant["variant-name"]

         });
      } else {
         if (viewerMode === "select") {
            setViewerMode("view")
         }
      
         setShowModal(true);

         ReactGA.event({
            category: "Modal View",
            action: "Augmented Reality",
            label: currentProduct["name"] + "|" + currentVariant["variant_name"] ?? currentVariant["variant-name"]
         });
      }
   }

   return (
      config.actions.ar && (
         <>
            <ActionButtonStandard label={t('arButtonName')} className={showActionIcons ? "" : "noIcons"} iconImg={ArIcon} handleClick={handleClick} />
            <QrCodeModal canShow={showModal} updateModalState={setShowModal} />
         </>
      )
   );
}
