import "./HotspotLabel.scss";
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import VimeoVideoHotspot from "../VideoContent/VimeoVideoHotspot";
import TextContent from "../TextContent/TextContent";
import LinkContent from "../LinkContent/LinkContent";
import ImageContent from "../ImageContent/ImageContent";
import { useVideoModal } from "../../../contexts/useVideoModal";
import YoutubeVideoHotspot from "../VideoContent/YoutubeVideoHotspot";
import FileUrlVideoHotspot from "../VideoContent/FileUrlVideoHotspot";
import {getPropertyOrDefault} from "../../../helpers/helpers";
import AnimationContent from "../AnimationContent/AnimationContent";
import { useTranslation } from 'react-i18next';

const HotspotLabel = ({hotspotData, clickable, hotspotActive, hotspotOptions}) => {

  const {t} = useTranslation();
    const labelAlignment = getPropertyOrDefault(hotspotData.labelAlignment, "right");
    const videoAutoplay = getPropertyOrDefault(hotspotData.videoAutoplay, true);
    const expandedByDefault = getPropertyOrDefault(hotspotData.expandable, false) ? false : true; //Show fully expanded if undefined
    const hotspotHeadingColor = getPropertyOrDefault(hotspotOptions?.hotspotHeadingColor, "#000000");
    const [expanded, setExpanded] = useState(expandedByDefault); 
    const [anchorPosition, setAnchorPosition] = useState({})
    const hotspotContent = getPropertyOrDefault(hotspotData.content, "");
    const [hotspotLabelContent, setHotspotLabelContent] = useState(typeof(hotspotContent) === "string"  ? (hotspotContent).split('\n') : hotspotContent );

    const {setVideoModalVisible, setVideoModalUrl, setVideoModalTitle, setVideoTextContent, setVideoModalSource, setVideoAutoplay, setCloseOnVideoEnded} = useVideoModal(); //the extra commas are here for a reason. actually valid syntax... amazes me too.

    const contentVariants = {
        open: { scale: 1, transition: {delay: 0.2}},
        closed: { scale: 0 },
      }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const getLabelAnimationState = () => {
        return (hotspotActive && clickable) ? "open" : "closed"
    }
    
    useEffect(() =>{
      if (typeof(hotspotContent) === "string"){
        setHotspotLabelContent((hotspotContent).split('\n'))
      }
      
        if (hotspotData.contentType.toLowerCase() === "video" && 
        hotspotData.videoMode.toLowerCase() === "hotspot") {
          switch (labelAlignment.toLowerCase()) {
            case "top":
              setAnchorPosition({originX: 0.5, originY: 1, translateX: "-50%", translateY: "0%"})
              break;
            case "right":
              setAnchorPosition({originX: 0, originY: 0.1, translateX: "100%", translateY: "-10%"})
              break;
            case "bottom":
              setAnchorPosition({originX: 0.5, originY: 0, translateX: "-50%", translateY: "0%"})
              break;
            case "left":
              setAnchorPosition({originX: 1, originY: 0.1, translateX: "-100%", translateY: "-10%"})
              break;
            default:
              setAnchorPosition({originX: 0, originY: 0.1, translateX: "0%", translateY: "0%"})
              break;
          }
        } else {
          switch (labelAlignment.toLowerCase()) {
            case "top":
              setAnchorPosition({originX: 0.5, originY: 1, translateX: "-50%", translateY: 0})
              break;
            case "right":
              setAnchorPosition({originX: 0, originY: 0.5, translateX: "100%", translateY: "-50%"})
              break;
            case "bottom":
              setAnchorPosition({originX: 0.5, originY: 0, translateX: "-50%", translateY: "0%"})
              break;
            case "left":
              setAnchorPosition({originX: 1, originY: 0.5, translateX: "-100%", translateY: "-50%"})
              break;
            default:
              setAnchorPosition({originX: 0, originY: 0.5, translateX: "0%", translateY: "0%"})
              break;
          }
        }
      }, [setAnchorPosition, hotspotData, hotspotContent, labelAlignment]
    )

    const handleVideoButtonClicked = () => {
      setVideoModalUrl(hotspotData.videoUrl);
      setVideoModalSource(hotspotData.videoSource);
      setVideoModalTitle(hotspotData.title);
      setVideoTextContent(hotspotData.content);
      setVideoModalVisible(true);
      setVideoAutoplay(videoAutoplay)
      setCloseOnVideoEnded(hotspotData.closeOnVideoEnded)
    }
        return (
            <motion.div
            style={{originX: anchorPosition.originX, originY: anchorPosition.originY, translateX: anchorPosition.translateX, translateY: anchorPosition.translateY}}
            className={`__label label-${labelAlignment}`}
            initial={"closed"}
            animate={getLabelAnimationState()}
            variants={contentVariants}
            >
               <motion.div
               className={`__content ${hotspotData.contentType === "video" && hotspotData.videoMode === "hotspot" && "_video"}`}
               >
                  {hotspotData.title && <div className="__title-bar"><div className="__title" style={{color:hotspotHeadingColor}}>{hotspotData.title}</div>{ hotspotData.expandable && <FontAwesomeIcon className="__toggle" onClick={toggleExpanded} icon={expanded? faChevronUp : faChevronDown} />}</div>}
                  {expanded && (<div className="__expandable-content">
                    {hotspotData.contentType === "animation" && <AnimationContent content={hotspotData.caption}/>}
                    {hotspotData.contentType === "text" && <TextContent content={hotspotLabelContent.map((line, index) => <span key={index}>{line}<br/></span>)}/>}
                    {hotspotData.contentType === "link" && <LinkContent content={hotspotLabelContent} linkUrl={hotspotData.linkUrl}/>}
                    {hotspotData.contentType === "image" && hotspotData.imageMode === "popup" && <ImageContent content={hotspotData.content}/>}
                    {(hotspotData.contentType === "video" && hotspotData.videoMode === "hotspot" && hotspotData.videoSource.toLowerCase() === "vimeo") && <VimeoVideoHotspot title={hotspotData.title} videoUrl={hotspotData.videoUrl} hotspotActive={hotspotActive} videoTextContent={hotspotData.content} videoAutoplay={hotspotData.videoAutoplay}/>}
                    {(hotspotData.contentType === "video" && hotspotData.videoMode === "hotspot" && hotspotData.videoSource.toLowerCase() === "youtube") && <YoutubeVideoHotspot title={hotspotData.title} videoUrl={hotspotData.videoUrl} hotspotActive={hotspotActive} videoTextContent={hotspotData.content} videoAutoplay={hotspotData.videoAutoplay}/>}
                    {(hotspotData.contentType === "video" && hotspotData.videoMode === "hotspot" && hotspotData.videoSource.toLowerCase() === "fileurl") && <FileUrlVideoHotspot title={hotspotData.title} videoUrl={hotspotData.videoUrl} hotspotActive={hotspotActive} videoTextContent={hotspotData.content} videoAutoplay={hotspotData.videoAutoplay}/>}
                    {(hotspotData.contentType === "video" && hotspotData.videoMode === "popup") && 
                    <>
                    <div className="hotspot__video-text-content">
                      <p>{hotspotLabelContent.map((line, index) => <span key={index}>{line}<br/></span>)}</p>
                    </div>
                    <button className="__content-link video-popup-button" onClick={handleVideoButtonClicked}>{t('viewVideo')}</button>
                    </>}

                  </div>)} 
               </motion.div>
            </motion.div>)
}


 
export default HotspotLabel;