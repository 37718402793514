import "./CustomLogo.scss";
import {getPropertyOrDefault} from "../../helpers/helpers";


export default function CustomLogo({logoData}) {

   const customLogoUrl = getPropertyOrDefault(logoData["custom-logo"], null)
   const customLogoInsetX = getPropertyOrDefault(logoData["custom-logo-inset-x"], "1.2rem")
   const customLogoInsetY = getPropertyOrDefault(logoData["custom-logo-inset-y"], "1.2rem")
   const customLogoWidth = getPropertyOrDefault(logoData["custom-logo-width"], "10rem")
   const customLogoWidthMobile = getPropertyOrDefault(logoData["custom-logo-width-mobile"], "5rem")
   const customLogoOpacity = getPropertyOrDefault(logoData["custom-logo-opacity"], "100%")
   const customLogoPosition = getPropertyOrDefault(logoData["custom-logo-position"], "right")
   const customLogoPositionAnchor = getPropertyOrDefault(logoData["custom-logo-position-anchor"], "bottom")


   return (
      (customLogoUrl) && (
         
            <img 
            src={customLogoUrl}
            alt="customLogo"
            className={`custom-logo ${customLogoPosition} position-anchor-${customLogoPositionAnchor}`}
            style={{
               "--custom-logo-width": customLogoWidth,
               "--custom-logo-width-mobile": customLogoWidthMobile,
               "--custom-logo-opacity": customLogoOpacity,
               "--custom-logo-inset-x": customLogoInsetX,
               "--custom-logo-inset-y": customLogoInsetY
            }}
            />
      )
   );
}
