import { useViewerMode } from "../../../contexts/useViewerMode";
import { useConfig } from "../../../contexts/useConfig";
import { useTranslation } from 'react-i18next';

import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import EyeIcon from "../../../assets/icons/eye.png";
import {getPropertyOrDefault} from "../../../helpers/helpers";

export default function ViewButton() {
   const {t} = useTranslation();
   const [config] = useConfig();
   const [viewerMode, setViewerMode] = useViewerMode();
   const showActionIcons = getPropertyOrDefault(config["show-action-icons"], true);

   return (
      config.actions.view && (
         <ActionButtonViewerState
            label={t('viewButtonName')}
            iconImg={EyeIcon}
            icon="fa-light fa-eye"
            className={showActionIcons ? "" : "noIcons"}
            action="view"
            active={viewerMode === "view"}
            handleStateButtonClicked={setViewerMode}
         />
      )
   );
}
