import "./InformationModal.scss";
import Modal from "../../Modals/Modal/Modal";
import CloseButton from "../../Buttons/Close/CloseButton";
import { useTranslation } from 'react-i18next';

import RotateIcon from "../../../assets/icons/rotate.svg";
import ScrollIcon from "../../../assets/icons/scroll.svg";
import PinchIcon from "../../../assets/icons/pinch.svg";
import SwipeIcon from "../../../assets/icons/swipe.svg";

export default function InformationModal(props) {
   const {t} = useTranslation();
   const isTouchEnabled =
      "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

   return (
      <Modal canShow={props.canShow} className="information-modal" onClickBackground={() => props.updateModalState(false)}>
         <CloseButton closeModal={() => props.updateModalState(false)} />
         <h2 className="information-modal__title">{t('informationHeading')}</h2>
         {isTouchEnabled ? (
            <div className="mobile-instructions">
               <img src={SwipeIcon} alt="Swipe to rotate" />
               <p>{t('rotateInstructionMobile')}</p>

               <img src={PinchIcon} alt="Pinch to zoom" />
               <p>{t('zoomInstructionMobile')}</p>
            </div>
         ) : (
            <div className="desktop-instructions">
               <img src={ScrollIcon} alt="Scroll mouse wheel to zoom" />
               <p>{t('zoomInstructionDesktop')}</p>

               <img src={RotateIcon} alt="Drag mouse to rotate" />
               <p>{t('rotateInstructionDesktop')}</p>
            </div>
         )}
      </Modal>
   );
}
