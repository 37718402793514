import { useConfig } from "../../../contexts/useConfig";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { useTranslation } from 'react-i18next';

import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import ExploreIcon from "../../../assets/icons/explore.png";
import { useCurrentVariant } from "../../../contexts/useCurrentVariant";
import {getPropertyOrDefault} from "../../../helpers/helpers";

export default function ArButton() {
   const {t} = useTranslation();
   const [config] = useConfig();
   const [viewerMode, setViewerMode] = useViewerMode();
   const [currentVariant] = useCurrentVariant();
   const showActionIcons = getPropertyOrDefault(config["show-action-icons"], true);

   // function isHotspots() {
   //    let isHotspots;
   //    config.products.forEach((product) => {
   //       product.variants.forEach((variant) => {
   //          if (variant.hotspots) {
   //             isHotspots = true;
   //          }
   //       });
   //    });
   //    return isHotspots;
   // }

   return (
      config.actions.explore && (
         <ActionButtonViewerState
            label={t('exploreButtonName')}
            iconImg={ExploreIcon}
            className={showActionIcons ? "" : "noIcons"}
            action="explore"
            handleStateButtonClicked={setViewerMode}
            active={viewerMode === "explore"}
            disabled={!currentVariant.hotspots}
         />
      )
   );
}
