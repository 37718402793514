import { motion } from "framer-motion";
import { useHotspotModal } from "../../../contexts/useHotspotModal"
import { getPropertyOrDefault } from "../../../helpers/helpers";
import LinkContent from "../LinkContent/LinkContent";
import TextContent from "../TextContent/TextContent";
import CloseIcon from "../../../assets/icons/close.png";
import "./HotspotModal.scss"
import ImageContent from "../ImageContent/ImageContent";


const HotspotModalPopup = () => {
    const {hotspotData, hotspotOptions, hotspotModalVisible, setHotspotModalVisible} = useHotspotModal();
    const hotspotContent = getPropertyOrDefault(hotspotData.content, "");
    const hotspotLabelContent = typeof(hotspotContent) === "string"  ? (hotspotContent).split('\n') : hotspotContent;
    const hotspotHeadingColor = getPropertyOrDefault(hotspotOptions?.hotspotHeadingColor, "#000000");

    const handleBackgroundClicked = () => {
        setHotspotModalVisible(false);
        }


        console.log(hotspotData)
    return (<>
        {hotspotModalVisible && <div className="pop-container">
        <section className="modal-hotspot-bg" onClick={handleBackgroundClicked}>
        <motion.div className="modal-hotspot-content"
            transition={{ type: "spring" }}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}>
            <div className="modal-hotspot">
            <div className="__title-bar">{hotspotData.title && <div className="__title" style={{color:hotspotHeadingColor}}><p>{hotspotData.title}</p></div>}
                <div className="modal-hotspot-close action-btn button--close" onClick={handleBackgroundClicked}>
                    <img src={CloseIcon} alt="icon" aria-hidden="true" className="action-btn__icon" />
                </div>
            </div>
            {hotspotData.contentType === "text" && <TextContent content={hotspotLabelContent.map((line, index) => <span key={index}>{line}<br/></span>)}/>}
            {hotspotData.contentType === "link" && <LinkContent content={hotspotLabelContent} linkUrl={hotspotData.linkUrl}/>}
            {hotspotData.contentType === "image" && hotspotData.imageMode === "popup" && <ImageContent content={hotspotData.content}/>}
            </div>
        </motion.div>
        </section>
        </div>}
        </>
      );
}
 
export default HotspotModalPopup;