import "./VideoContent.scss"
import Player from '@vimeo/player';
import { useState, useEffect, useRef } from "react"
const VimeoVideoHotspot = ({videoUrl, hotspotActive, videoTextContent, videoAutoplay}) => {
   const videoFrameRef = useRef({hotspotActive});
   const [player, setPlayer] = useState(null); 

   useEffect(() => {
      setPlayer(new Player(videoFrameRef.current));
   }, [videoFrameRef])

   useEffect(() => {
      if (player) {
         if (hotspotActive) {
            player.play();
         } else {
            player.pause();
         }
      }
   }, [hotspotActive, player])

   return (
      <div>
         <div >
            <iframe
               ref={videoFrameRef}
               title={`Vimeo Player: ${videoUrl}`}
               className="__video-content"
               src={`${videoUrl}&amp;badge=0&amp;autoplay=${videoAutoplay ? 1 : 0};autopause=0`}
               frameBorder="0"
               allow="autoplay; fullscreen; picture-in-picture"
               allowFullScreen
            ></iframe>
         </div>
         <div className="modal__video-text-content">
            <p>{videoTextContent}</p>
         </div>
      </div>
   );
};

export default VimeoVideoHotspot;
