import "./DisclaimerAccordionModal.scss";
import DisclaimerInfoIcon from "../../../../assets/icons/info-icon.png";
import { useConfig } from "../../../../contexts/useConfig";
import { useEffect, useState } from "react";
import { getPropertyOrDefault } from "../../../../helpers/helpers";
//import { motion } from "framer-motion"


export default function DisclaimerAccordionModal(props) {
   const [config] = useConfig();

   const disclaimerIsVisible = config.disclaimerInformation?.disclaimerMode === "alwaysVisible";
   const disclaimerContent = getPropertyOrDefault(config.disclaimerInformation?.disclaimerInfoText, "");
   const [disclaimerTextContent, setDisclaimerTextContent] = useState(typeof (disclaimerContent) === "string" ? (disclaimerContent).split('\n') : disclaimerContent);
   //const [expanded, setExpanded] = useState(false);


   // const contentVariants = {
   //    open: {maxHeight: "100%"},
   //    closed: { maxHeight: "69px" },
   // }

   // const toggleExpandDisclaimer = () => {
   //    setExpanded(!expanded);
   // }

   useEffect(() => {
      if ("isVisible" in props && !props.isVisible) {
         return null;
      }
      if (typeof (disclaimerContent) === "string") {
         setDisclaimerTextContent((disclaimerContent).split('\n'))
      }
   }, [disclaimerContent, props])

   return (<>
      {disclaimerIsVisible && 
         <div className={"disc-acc-main-cont " + props.deviceType}>
         <div className="disclaimer-acc-modal">
            <div className="da-grid">
               <div className="da-icon-cont">
                  <img src={DisclaimerInfoIcon} className="disc-acc-icon" alt="Disclaimer-Icon" />
               </div>
               <div className={`da-inner-container`}>
                  {(config.disclaimerInformation?.disclaimerInfoHeading &&
                     <h2 className="da-modal__heading">{(config.disclaimerInformation?.disclaimerInfoHeading)}</h2>
                  )}
                  <p className="da-inner-container__text">{disclaimerTextContent.map((line, index) => <span key={index}>{line}<br /></span>)}</p>
               </div>
            </div>
            {/* <div className="expand-button">
               <button onClick={toggleExpandDisclaimer}>{expanded ? "show less" : "read more"}</button>
            </div> */}
         </div>
      </div>
      }
      </>
   );
}
