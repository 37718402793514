import ReactGA from "react-ga4";

export function useGoogleAnalytics() {
   function getTrackingID() {
      const params = new URLSearchParams(window.location.search);
      const gaId = params.get("ga_id");
      if(gaId){
         //console.log(`Got Google Analytics measurement ID: ${gaId}`)
      }
      else {
         console.log(`Google Analytics measurement ID not set`)
      }
      return gaId;
   }

   function initGoogleAnalytics() {
      ReactGA.initialize(trackingID, {
         debug: true,
      });
      ReactGA.send("pageview");
   }

   const trackingID = getTrackingID();
   trackingID && initGoogleAnalytics();
}
