import LanguageSwitcher from "./LanguageSwitcher";
import { useConfig } from "../../../contexts/useConfig";
import "./RegionButton.scss";
import React from 'react';

const RegionButton = () => {
  const [config] = useConfig();
    /*const languages = [
        { code: 'en-GB', name: 'English'},      
        { code: 'fr-FR', name: 'French'},
        { code: 'es-ES', name: 'Spanish'},
        { code: 'it-IT', name: 'Italian'},
        { code: 'de-DE', name: 'Deutsch'},
        { code: 'ru-RU', name: 'Русский'},
        { code: 'pl-PL', name: 'Polish'}
      ]*/

    return ( 
      config.actions.region && (
        <>
           {/*<LanguageSwitcher languages={config.regionLanguages}/>*/}
           <LanguageSwitcher/>
        </>
      )        
     );
}
 
export default RegionButton;